.accordion {
    width: 100%;
    max-width: 75%;
    margin: 0 auto;
    position: relative;
}

.panel {
    /* background-color: black; */
    background-color: transparent;
    border-color: #2bbd4f;
    border-width: 3px;
    border-radius: 12px;
    margin-top: 10px;
}

.panel__label {
    display: block;
    width: 100%;
    background: none;
    border-radius: 12px;
    border: none;
    text-align: left;
    padding: 27px 65px 27px 30px;
    font-weight: 400;
    font-size: 18px;
    font-family: inherit;
    transition: color 0.1s linear;
    cursor: pointer;
    color: rgb(255, 255, 255);
    height: fit-content;

}

.panel__label:focus {
    outline: none;
}

.panel__label:after,
.panel__label:before {
    content: "";
    position: absolute;
    right: 25px;
    top: 50%;
    width: 22px;
    height: 2px;
    margin-top: -2px;
    background-color: #ffffff;
}

.panel__label:before {
    transform: rotate(-90deg);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel[aria-expanded="true"] .panel__content {
    opacity: 1;
}

.panel[aria-expanded="true"] .panel__label:before {
    transform: rotate(0deg);
}

.panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel__content {
    margin: 5px 25px 25px;
    font-size: 17px;
    color: #e2e2e2;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
}

.panel:not(:last-child) {
    margin-bottom: 3px;
}
