@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');

.Whole_div {
    background-repeat: repeat;
    background-size: auto 100vh;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    color: white;
}

.mediaInfo {
    margin-top: 10vh;
    background-size: auto 100vh;
}

.color_section {
    background-color: #05181a;
    padding-bottom: 5rem;
}

h1 {
    text-align: center;
    font-size: 5rem;
    color: #2fda68;
}

.Row {
    text-align: center;
}

.info {
    margin-top: 20vh;
    color: white;
}

.d-image {
    padding-right: 50px;
}

.d-image img {
    width: 100%;
    position: relative;
    transition: 0.3s;
    top: 0;
    transition: top 0.2s linear;
}

.d-image img:hover {
    opacity: 0.9;
    top: 10px;
}

.logoSection {
    text-align: center;
    padding: 20vh 10vh 0 10vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.members {
    padding: 0 10vh 0 10vh;
}

.prize_section {
    text-align: center;
    margin: 4vh 8vw;
    padding: 4vh 4vw;
    border-radius: 14px;
    background-color: transparent;
}

.Prizes {
    width: 100%;
}

.sponsorSection {
    padding: 17vh 18vh 0 18vh;
    margin-top: 0;
}

@media (max-width: 570px) {
    .mediaInfo {
        margin-top: 0;
    }

    .Whole_div {
        background-size: auto 100vh;
    }

    .members {
        margin: 0;
        padding: 0;
    }

    .prize_section {
        margin: 5vh 0 0 0;
        padding: 17vh 0 0 4vh;
    }

    .sponsorSection {
        padding: 0;
        margin: 0;
    }

    .logoSection {
        text-align: center;
        padding: 20vh 0 0 0;
    }

    .d-image {
        padding-right: 0;
    }
}

#faq, #prizes, #team {
    padding-top: 150px;
}