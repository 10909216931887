.member {
    text-align: center;
    margin-top: 5rem;
    position: relative;
    top: 0;
    transition: top 0.2s linear;
}

.member:hover {
    top: -5px;
    opacity: 0.90;
}

.member img {
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    border: 5px solid rgb(144, 255, 194);
}

.members-link {
    text-align: center;
}

.members-link a {
    margin: 0 7px;
    cursor: pointer;
}

.joinT {
    text-align: center;
}

.joinT h3 {
    display: inline-block;
    font-weight: 500;
    margin-right: 40px;
}

@media (max-width: 570px) {
    .member {
        margin-top: 4rem;
    }

    .member img {
        width: 25rem;
        height: 25rem;
    }

    .joinT h3 {
        margin-right: 0;
    }
}
