
.Prizes {
    width: 32rem;
    width: fit-content;
    text-align: left;
    margin: 1.7rem auto 0;
}

.PrizeHeading h1 {
    font-size: 5rem;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;

}

.Prizes p {
    color: rgb(255, 255, 255);
}

.Prizes .first {
    color: #2fda68;
}

