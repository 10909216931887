@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap');

.h1 {
    margin: 0;
}

button {
    width: 22rem;
    height: 6rem;
    border: none;
    border-radius: 19px;
    font-size: 2rem;
    color: rgb(255, 255, 255);
    background-color: #299713;
    font-weight: 300;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.join_disco {
    width: 25rem;
    background: linear-gradient(90deg, #007fe7 30%, #50ec6a 100%);
}

.join_disco i {
    margin-right: 10px;
}

.join_dis {
    margin-bottom: 29px;
    margin-top: 19px;
}

.button-group {
    width: 100%;
    display: flex;
}

/* make button group vertical if media size is mobile */
@media (max-width: 570px) {
    .button-group {
        flex-direction: column;
        gap: 1rem;
    }
}

.register {
    margin-left: 2rem;
    border: 2px solid rgb(255, 255, 255);
    background-color: transparent;
}

.AboutMe h2 {
    font-size: 8rem;
    font-family: 'Noto Sans', sans-serif;
}

p {
    font-size: 2rem;
    line-height: 1.6;
    font-weight: 300;
}

.Myinfo {
    text-align: left;
    margin-left: 10vh;
}

.Myinfo p {
    width: 70%;
}

/* Overlay effect */
.overlay {
    position: absolute;
    bottom: 0;
    left: 100%;
    right: 0;
    background-color: white;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .3s ease;
    border-radius: 12px;
}

.overlay-text {
    font-weight: 300;
    color: #2C4677;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    --webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

button:hover .overlay {
    width: 100%;
    left: 0;
}

/* main target */
@media (max-width: 570px) {
    .Myinfo p {
        width: 100%;
    }

    .AboutMe h2 {
        font-size: 4rem;
    }

    .Myinfo {
        text-align: center;
        margin-left: 0;
    }

    .Myinfo p {
        font-size: 1.9rem;
    }

    .AboutMe {
        text-align: center;
    }
}

@media (max-width: 330px) {
    .AboutMe h1 {
        font-size: 2.2em;
    }

    .Myinfo p {
        font-size: 1.6rem;
    }
}